import { addClass } from '@/common/dom'
import Tool from './tool'
import html2canvas from 'html2canvas'
import { saveCanvas } from '@/common/utils'
import miment from 'miment'
const mixin = {
  data() {
    return {
      miment,
      full: false
    }
  },
  components: {
    Tool
  },
  mounted() {
    //this.computeHeight()
  },
  watch: {
    full(val) {
      if (!val) {
        this.computeHeight()
      }
    }
  },
  methods: {
    // 防止transform模糊
    computeHeight() {
      setTimeout(() => {
        if (this.$el.children[0]) {
          const height = this.$el.children[0].clientHeight
          if (height % 2 === 1) {
            addClass(this.$el.children[0], 'el-dialog--needOnePiexl')
          }
        }
      }, 25)
    },
    download(name) {
      html2canvas(this.$el.children[0]).then(canvas => {
        saveCanvas(canvas, name)
        this.$el.children[0].appendChild(canvas)
      })
    }
  }
}

export default mixin
